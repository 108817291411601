import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import TenantsGrid from './components/Tenants/TenantsGrid';
// ... import other page components

const RoutesComponent = ({ user, signOut }) => {
    return (
        <Routes>
            <Route path="/" element={<Home user={user} signOut={signOut} />} />
            <Route path="/tenants" element={<TenantsGrid />} />
            {/* ... other routes */}
        </Routes>
    );
};

export default RoutesComponent;
