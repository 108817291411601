import React from 'react';
import { Link } from 'react-router-dom';

const Home = ({ user, signOut }) => {
    return (
        <div>
            <h1>Hello {user.attributes.email}</h1>
            <button onClick={signOut}>Sign out</button>
            <Link to="/tenants">Go to Tenants Grid</Link> {/* Link to navigate to Tenants Grid */}
        </div>
    );
};

export default Home;
