import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

function TenantsGrid() {
    const [tenants, setTenants] = useState([]);

    // Function to fetch the authentication token
    async function fetchToken() {
        try {
            const session = await Auth.currentSession();
            return session.getIdToken().getJwtToken();
        } catch (error) {
            console.error('Error fetching token', error);
        }
    }

    // Function to fetch tenants data
    async function fetchTenants() {
        let token;
        try {
            const session = await Auth.currentSession();
            token = session.getIdToken().getJwtToken();
        } catch (error) {
            console.error('Error fetching token', error);
            return; // If there's an error fetching the token, exit the function.
        }

        try {
            const response = await fetch('https://api.masterworkz.net/tenants', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            // log the response for debugging
            console.log('Response received:', response);

            const responseBody = await response.text(); // Using text() to ensure we can log even non-JSOn responses

            // Check if the response is OK (status code in the range 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status} - ${responseBody}`);
            }

            // Try to parse the response body as JSON
            let data;
            try {
                data = JSON.parse(responseBody);
            } catch (parseError) {
                throw new Error('Failed to parse response as JSON: ' + responseBody);
            }

            if (data) {
                setTenants(data);
            } else {
                throw new Error('No data returned from the API');
            }
        } catch (error) {
            console.error('Error fetching tenants:', error.message);
        }
    }

// Fetch tenants data on component mount
useEffect(() => {
    fetchTenants();
}, []);


    return (
        <div className="grid-container">
            {tenants.map(tenant => (
                <div key={tenant.id} className="grid-item">
                    <p>{tenant.name}</p>
                    {/* More tenant details */}
                </div>
            ))}
        </div>
    );
}

export default TenantsGrid;
