import React from 'react';
import './App.css';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import "@aws-amplify/ui-react/styles.css";
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter
import RoutesComponent from './Routes'; // Make sure the path is correct based on your file structure

Amplify.configure(awsExports);

export default function App() {
  return (
    <BrowserRouter> {/* Wrap your component within BrowserRouter */}
      <Authenticator socialProviders={['apple', 'google']}>
        {({ signOut, user }) => (
          // Instead of directly rendering content here, use RoutesComponent
          <RoutesComponent user={user} signOut={signOut} />
        )}
      </Authenticator>
    </BrowserRouter>
  );
}

